@import "sass/variables";
@import "sass/bootstrap/variables"; // @import "sass/tag-form";
// VERSIONE 02 //////

.article-single {
	> h1 {
		padding-right: 20px;
		padding-left: 20px;
		@extend .posizionecontenuto--centrale;

		input:focus::-webkit-input-placeholder {
			color: transparent;
		}

		input:focus:-moz-placeholder {
			color: transparent;
		}
		/* FF 4-18 */
		input:focus::-moz-placeholder {
			color: transparent;
		}
		/* FF 19+ */
		input:focus:-ms-input-placeholder {
			color: transparent;
		}
		/* IE 10+ */
		@media (min-width: $screen-sm-min) {
			//                              width: $container-sm;
			//                              padding-right: 60px;
			//                              padding-left: 210px;
			//                              font-size: 2.65em;
		}
		@media (min-width: $screen-md-min) {
			//                              width: $container-md;
			//                              padding-right: 100px;
			//                              padding-left: 260px;
			//                              padding-left: 27vw;
			//                              padding-right: 8vw;
		}
		@media (min-width: $screen-lg-min) {
			//                              width: $container-lg;
			//                              padding-left: 20vw;
			//                              padding-right: 7vw;
			//                              padding-right: 120px;
			//                              padding-left: 310px;
		}
		position: relative;
		display: block;
		//                    margin: auto;
		// overflow: hidden; s
		font-size: 2.2em;
		line-height: 1.3;
		font-weight: 600;
		-webkit-margin-before: 0;
		-webkit-margin-after: 0.6em;
		margin: 25px auto;
		color: $titlecolor;
		font-family: $fontCalvert;
		text-rendering: optimizeLegibility;
	}
}

.posizionecontenuto {
	padding-right: 20px;
	padding-left: 20px;
	@media (min-width: $screen-sm-min) {
		width: $container-sm;
		padding-right: 60px;
		padding-left: 210px;
	}
	@media (min-width: $screen-md-min) {
		width: $container-md;
		padding-right: 100px;
		padding-left: 260px;
		//                              padding-left: 27vw;
		//                              padding-right: 8vw;
	}
	@media (min-width: $screen-lg-min) {
		width: $container-lg;
		//                              padding-left: 20vw;
		//                              padding-right: 7vw;
		padding-right: 120px;
		padding-left: 310px;
	}
}

.posizionecontenuto--centrale {
	max-width: 740px;
	padding-left: 20px;
	padding-right: 20px;
	@media (min-width: $screen-sm-min) {
		width: $container-sm;
		//                    padding-right: 60px;
		//                    padding-left: 210px;
	}
	@media (min-width: $screen-md-min) {
		width: $container-md;
		//                    padding-right: 100px;
		//                    padding-left: 260px;
		//                              padding-left: 27vw;
		//                              padding-right: 8vw;
	}
	@media (min-width: $screen-lg-min) {
		//                    width: 800px;
		//                    width: $container-lg;
		//                    padding-left: 100px;
		//                    padding-right: 160px;
		//                              padding-left: 20vw;
		//                              padding-right: 7vw;
		//                    padding-right: 120px;
		//                    padding-left: 310px;
	}
	@media (max-width: 768px){
		padding-right: 60px;
	}
}

.posizionesinistra {
	padding-left: 5px;
	padding-right: 10px;
	@media (min-width: $screen-sm-min) {
		width: $container-sm;
		padding-left: 5px;
		padding-right: 10px;
	}
	@media (min-width: $screen-md-min) {
		width: $container-md;
		padding-left: 15px;
		padding-right: 20px;
	}
	@media (min-width: $screen-lg-min) {
		width: $container-lg;
		padding-left: 15px;
		padding-right: 30px;
		//
		color: #f9322d;
	}
}

.content--left {
	width: auto;
	@media (min-width: $screen-sm-min) {
		width: $container-sm;
		// max-width: 185px;
	}
	@media (min-width: $screen-md-min) {
		width: $container-md;
		max-width: 220px;
	}
	@media (min-width: $screen-lg-min) {
		width: $container-lg;
		max-width: 250px;
	}
}

.sez-base-primary {
	&:focus {
		outline-style: none;
		outline-width: 0;
		outline-color: #ffffff;
	}

	// p {
	//	margin-bottom: 35px;
	// }

	> p {
		@extend .posizionecontenuto--centrale;
		@media (min-width: $screen-sm-min) {
			margin-bottom: 65px;
			-webkit-margin-before: 0 !important;
			-webkit-margin-after: 3em !important;
		}
		position: relative;
		display: block;
		margin: auto;
		// overflow: hidden;
		// font-size: 20px;
		//                    font-size: 1.4em;
		font-size: 20px;
		line-height: 1.4em;
		font-weight: lighter;
		margin-bottom: 35px;
		//                    display: block;
		//                    -webkit-margin-before: 0px !important;
		//                    -webkit-margin-after: 1.5em !important;
		//                    -webkit-margin-start: 0px;
		//                    -webkit-margin-end: 0px;
		//                    background-color: #0000fe;
	}

	a {
		font-weight: bold;
		cursor: pointer;
	}
	//FINE P
	> h2 {
		//                    @extend .posizionecontenuto;
		@extend .posizionecontenuto--centrale;
		position: relative;
		display: block;
		margin: auto;
		// overflow: hidden;
		font-size: 1.65em;
		line-height: 1.2;
		font-weight: 600;
		-webkit-margin-before: 0;
		-webkit-margin-after: 0.6em;
		margin-bottom: 15px;
		color: $titlecolor;
		font-family: $fontCalvert;
		text-rendering: optimizeLegibility;
	}
	// FINE H2
	> h3 {
		@extend .posizionecontenuto--centrale;
		position: relative;
		display: block;
		margin: auto;
		// overflow: hidden;
		font-size: 1.3em;
		line-height: 1.45;
		font-weight: 400;
		-webkit-margin-before: 0;
		-webkit-margin-after: 0.6em;
		margin-bottom: 15px;
		color: $titlecolor;
		font-family: $fontCalvert;
		text-rendering: optimizeLegibility;
	}

	> ul {
		@extend .posizionecontenuto--centrale;
		@media (min-width: $screen-md-min) {
			margin: 0 auto 65px;
		}
		position: relative;
		display: block;
		margin: 0 auto 35px;

		li {
			margin-left: 20px;
			font-size: 20px;
			line-height: 1.4;
			font-weight: lighter;
		}
	}
	//          .imgcentrale {
	//                    margin: 0px auto 15px auto;
	//                    -webkit-margin-before: 0px;
	//                    -webkit-margin-after: 3em;
	//
	//                    @media (min-width: $screen-md-min) {
	//                              margin-bottom: 35px;
	//
	//                    }
	//
	//                    //
	//                    //                    img { width: 100%;}
	//                    //
	//                    @media (min-width: $screen-sm-min) {
	//                              width: $container-sm;
	//                              // padding-left: 0px;
	//                              // padding-right: 100px;
	//                    }
	//                    @media (min-width: $screen-md-min) {
	//                              width: $container-md;
	//                              padding-right: 100px;
	//                              padding-left: 15px;
	//
	//                    }
	//                    @media (min-width: $screen-lg-min) {
	//                              width: $container-lg;
	//                              padding-right: 120px;
	//                              padding-left: 15px;
	//                              //                              padding-left:4vw;
	//                              //                              padding-right: 7vw;
	//
	//                    }
	//                    //
	//                    figcaption {
	//
	//
	//
	//                              &:before {
	//
	//                                        content: '\f077';
	//                                        font-family: fontawesome;
	//                                        position: absolute;
	//                                        top: -12px;
	//                                        font-size: 10px;
	//
	//                              }
	//
	//                              @media (min-width: $screen-md-min) {
	//                                        position: absolute;
	//                                        padding: 0px 0px 10px 0px;
	//                                        margin: 25px 0px;
	//
	//                              }
	//
	//                              font-size: 0.9em;
	//                              line-height: 1.3em;
	//                              font-style: italic;
	//                              font-weight: 300;
	//                              width: 100%;
	//                              margin: 0px 30px 0px 20px;
	//                              padding: 0px 0px 20px 0px;
	//                              border-bottom: 1px dotted $txtchiarocolor;
	//                              color: $txtcolor;
	//                              -webkit-margin-before: 2em;
	//                              margin-top: 25px;
	//                              // width: 15vw;
	//                              display: block;
	//                              position: relative;
	//                              z-index: 10;
	//
	//                              @extend .content--left;
	//                              @extend .posizionesinistra;
	//
	//                              display: block;
	//                              position: relative;
	//
	//                    }
	//                    //
	//          }
	//          .imgfullsize {
	//
	//                    -webkit-margin-before: 0px;
	//                    -webkit-margin-after: 3em;
	//                    margin-bottom: 35px;
	//                    z-index: 10;
	//                    position: relative;
	//
	//
	//                    figcaption {
	//                              &:before {
	//
	//                                        content: '\f077';
	//                                        font-family: fontawesome;
	//                                        position: absolute;
	//                                        top: -12px;
	//                                        font-size: 10px;
	//
	//                              }
	//                    }
	//
	//                    .didafoto {
	//                              @extend .posizionesinistra;
	//                              margin: auto;
	//                              position: relative;
	//
	//                    }
	//
	//                    figcaption {
	//                              @extend .content--left;
	//
	//
	//                              &:before {
	//
	//                                        content: '\f077';
	//                                        font-family: fontawesome;
	//                                        position: absolute;
	//                                        top: -12px;
	//                                        font-size: 10px;
	//
	//                              }
	//
	//                              @media (min-width: $screen-md-min) {
	//                                        position: absolute;
	//                                        padding: 0px 0px 10px 0px;
	//                                        margin: 25px 0px;
	//                                        float: left;
	//
	//                              }
	//
	//                              //                              width: 250px;
	//
	//                              font-size: 0.9em;
	//                              font-style: italic;
	//                              font-weight: 300;
	//                              //                              padding: 0px 0px 10px 0px;
	//                              margin: 0px 20px 0px 20px;
	//                              padding: 0px 0px 20px 0px;
	//                              border-bottom: 1px dotted $txtchiarocolor;
	//                              color: $txtcolor;
	//                              -webkit-margin-before: 2em;
	//                              margin-top: 25px;
	//
	//                              position: relative;
	//                              display: block;
	//                              z-index: 10;
	//
	//                    }
	//          }
	//          .imgleft {
	//
	//                    @extend .posizionesinistra;
	//
	//                    margin: auto;
	//                    position: relative;
	//                    margin-bottom: 35px;
	//
	//                    @media (min-width: $screen-md-min) {
	//                              margin-bottom: 5px;
	//
	//                    }
	//                    // z-index: 15;
	//
	//                    .aspectratio {
	//
	//                              @extend .content--left;
	//
	//                              @media (min-width: $screen-sm-min) {
	//                                        float: left;
	//                              }
	//
	//                    }
	//
	//                    figcaption {
	//
	//                              &:before {
	//
	//                                        content: '\f077';
	//                                        font-family: fontawesome;
	//                                        position: absolute;
	//                                        top: -12px;
	//                                        font-size: 10px;
	//
	//                              }
	//                              @extend .content--left;
	//
	//                              @media (min-width: $screen-md-min) {
	//                                        margin-top: 25px;
	//                                        position: absolute;
	//                                        padding: 0px 0px 10px 0px;
	//                                        margin: 25px 0px 10px 0px;
	//
	//                              }
	//
	//                              margin: 0px 20px 0px 20px;
	//                              padding: 0px 0px 20px 0px;
	//                              border-bottom: 1px dotted $txtchiarocolor;
	//                              position: relative;
	//                              display: block;
	//
	//                              font-size: 0.9em;
	//                              font-style: italic;
	//                              font-weight: 300;
	//
	//                              color: $txtcolor;
	//                              -webkit-margin-before: 2em;
	//
	//                              z-index: 10;
	//
	//                    }
	//
	//          }
	.box--sinistro {
		//                    ========================
		//                    ==== ATTENZIONE =========
		visibility: hidden;
		display: none;
		//                    ========================
		//                    ========================
		position: relative;
		z-index: 7;
		margin: 10px 20px 25px;
		padding: 0;
		@media (min-width: $screen-md-min) {
			margin: auto;
			padding: 0 0 0 20px;
		}
		@extend .posizionesinistra;

		.content--sx {
			@media (min-width: $screen-md-min) {
				float: left;
				border: none;
				padding: 0;
			}
			border-bottom: 1px dotted $txtchiarocolor;
			border-top: 1px dotted $txtchiarocolor;
			padding: 20px 0;
			color: $txtcolor;
			font-weight: 300;
			font-style: italic;
			font-size: 0.9em;
			line-height: 1.35em;
			@extend .content--left;

			> h2 {
				font-size: 22px;
				font-weight: 700;
				margin-bottom: 0;
				margin-top: 0;
			}
		}
	}

	.slider {
		position: relative;
		//                    visibility: hidden;
		z-index: 10;
		margin-bottom: 35px;
	}

	.imgfullsize {
		-webkit-margin-before: 0;
		-webkit-margin-after: 3em;
		margin-bottom: 35px;
		z-index: 10;
		position: relative;

		img {
			width: 100%!important;
		}

		figcaption {
			&:before {
				// content: '\f077';
				// font-family: fontawesome;
				// position: absolute;
				// top: -12px;
				// font-size: 10px;
			}
		}

		figcaption {
			//                              @extend .content--left;
			&:before {
				// content: '\f030';
				// font-style: normal;
				// font-family: fontawesome;
				// position: absolute;
				// color: #ffffff;
				// top: -12px;
				// left: -10px;
				// background-color: #00c5f9;
				// border-radius: 50px;
				// padding: 5px;
				// font-size: 10px;
			}
			@media (min-width: $screen-md-min) {
				position: absolute;
				//                                     margin: 25px 0px;
				float: left;
				width: 180px;
				top: 20px;
				left: 20px;
				background-color: #ffffff;
				padding: 6px;
				border-radius: 2px;
				border: none;
			}
			//                              width: 250px;
			font-size: 0.9em;
			font-style: italic;
			font-weight: 300;
			//                              padding: 0px 0px 10px 0px;
			margin: 0 20px;
			padding: 0 0 20px;
			border-bottom: 1px dotted $txtchiarocolor;
			color: $txtcolor;
			-webkit-margin-before: 2em;
			margin-top: 25px;
			position: relative;
			display: block;
			z-index: 10;
		}
	}

	.medium-insert-embeds-overlay {
		height: 90% !important;
	}

	.medium-insert-embeds {
		figcaption {
			display: block;
			margin: 10px 0 0;
			padding: 0 20px 5px;
			font-style: italic;
			text-align: center;
			// border-left: 2px #ccc solid;
			// max-width: 185px
			border-bottom: 1px dotted #cbcdcd;
			font-size: 0.9em;
			line-height: 1.3em;

		}

		figure {
			@media (min-width: $screen-md-min) {
				margin-bottom: 35px;
			}
			@media (min-width: $screen-sm-min) {
				width: $container-sm;
			}
			@media (min-width: $screen-md-min) {
				max-width: 960px;
				height: 80%;
			}
			@media (min-width: $screen-lg-min) {
				width: $container-lg;
			}
		}
		margin: 0 auto 15px;
		-webkit-margin-before: 0;
		-webkit-margin-after: 3em;
		position: relative;
		@media (min-width: $screen-md-min) {
			margin-bottom: 35px;
		}
		@media (min-width: $screen-sm-min) {
			width: $container-sm;
		}
		@media (min-width: $screen-md-min) {
			max-width: 960px;
		}
		@media (min-width: $screen-lg-min) {
			width: $container-lg;
		}
	}

	.imgcentrale {
		margin: 0 auto 15px;
		-webkit-margin-before: 0;
		-webkit-margin-after: 3em;
		position: relative;
		@media (min-width: $screen-md-min) {
			margin-bottom: 35px;
		}
		//
		img {
			width: 100%;
		}
		//
		@media (min-width: $screen-sm-min) {
			width: $container-sm;
			// padding-left: 0px;
			// padding-right: 100px;
		}
		@media (min-width: $screen-md-min) {
			max-width: 960px;
			//                              width: $container-md;
			//                              padding-right: 100px;
			//                              padding-left: 15px;
			//                              padding-left: 100px;
		}
		@media (min-width: $screen-lg-min) {
			width: $container-lg;
			//                              padding-right: 120px;
			//                              padding-left: 15px;
			//                              padding-left:4vw;
			//                              padding-right: 7vw;
		}
		//
		figcaption {
			&:before {
				// content: '\f030';
				// font-style: normal;
				// font-family: fontawesome;
				// position: absolute;
				// color: #ffffff;
				// top: -12px;
				// left: -10px;
				// background-color: #00c5f9;
				// border-radius: 50px;
				// padding: 5px;
				// font-size: 10px;
			}
			@media (min-width: $screen-md-min) {
				position: absolute;
				//                                     margin: 25px 0px;
				float: left;
				top: 20px;
				left: 20px;
				background-color: #ffffff;
				padding: 6px;
				border-radius: 2px;
				border: none;
				width: 230px;
				line-height: 18px;
			}
			font-size: 0.9em;
			line-height: 1.3em;
			font-style: italic;
			font-weight: 300;
			width: 100%;
			// margin: 0 30px 0 20px;
			padding: 0 20px 5px;
			border-bottom: 1px dotted $txtchiarocolor;
			color: $txtcolor;
			-webkit-margin-before: 1em;
			// margin-top: 25px;
			// width: 15vw;
			display: block;
			position: relative;
			z-index: 8;
			@extend .content--left;
			@extend .posizionesinistra;
			display: block;
			position: relative;
		}
		//
	}

	.imgleft {
		@extend .posizionesinistra;

		img {
			margin: auto;
			position: relative;
			margin-bottom: 35px;
			@media (min-width: $screen-md-min) {
				margin-bottom: 5px;
			}
			// z-index: 15;
			.aspectratio {
				@extend .content--left;
				@media (min-width: $screen-sm-min) {
					float: left;
				}
			}
		}

		figcaption {
			&:before {
				// content: '\f077';
				// font-family: fontawesome;
				// position: absolute;
				// top: -12px;
				// font-size: 10px;
			}
			@extend .content--left;
			@media (min-width: $screen-md-min) {
				margin-top: 25px;
				position: absolute;
				padding: 0 0 10px;
				margin: 25px 0 10px;
			}
			margin: 0 20px;
			padding: 0 0 20px;
			border-bottom: 1px dotted $txtchiarocolor;
			position: relative;
			display: block;
			font-size: 0.9em;
			font-style: italic;
			font-weight: 300;
			color: $txtcolor;
			-webkit-margin-before: 2em;
			z-index: 8;
		}
	}
}
//.medium-editor-placeholder:after{
//          color: #54f953 !important;
//
//          display: block;
//          margin-bottom: 65px;
//-webkit-margin-before: 0px !important;
//-webkit-margin-after: 3em !important;
//
//}
//
//
//          .medium-editor-placeholder {
//
//              margin-bottom: 65px;
//-webkit-margin-before: 0px !important;
//-webkit-margin-after: 3em !important;
//          color: #00c5f9!important;
//
//}

.medium-editor-placeholder {
	@extend .posizionecontenuto;
	margin: auto;
}

.medium-editor-insert-plugin.medium-editor-placeholder:after {
	@extend .posizionecontenuto--centrale;
	@media (min-width: $screen-sm-min) {
		margin-bottom: 65px;
		-webkit-margin-after: 3em !important;
	}
	// overflow: hidden;
	//                    font-size: 20px;
	font-size: 1.4em;
	line-height: 1.4;
	font-weight: lighter;
	margin-bottom: 35px;
	//                    display: block;
	//                    -webkit-margin-before: 0px!important;
	//                    -webkit-margin-after: 1.5em!important;
	//                    -webkit-margin-start: 0px;
	//                    -webkit-margin-end: 0px;
	//                    background-color: #0000fe;
}

.medium-editor-insert-plugin:after {
	@extend .posizionecontenuto--centrale;
}

.medium-editor-placeholder {
	margin-top: -10px;
}

.medium-insert-buttons {
	width: 100%;

	.contenitore-bottone {
		padding-right: 20px;
		padding-left: 20px;
		margin: auto;
		@media (min-width: $screen-sm-min) {
			width: $container-sm;
			padding-right: 60px;
			//                              padding-left: 210px;
		}
		@media (min-width: $screen-md-min) {
			width: $container-md;
			padding-right: 100px;
			//                              padding-left: 260px;
			//                              padding-left: 27vw;
			//                              padding-right: 8vw;
		}
		@media (min-width: $screen-lg-min) {
			width: $container-lg;
			//                              padding-left: 20vw;
			//                              padding-right: 7vw;
			padding-right: 120px;
			//                              padding-left: 310px;
		}
		@media (min-width: $screen-sm-min) {
			margin-bottom: 65px;
			-webkit-margin-after: 3em !important;
		}
	}

	.medium-insert-buttons-show {}
}
//.medium-editor-insert-plugin:before {
//      color: #f9152e !important;
//}

.medium-insert-images {
	margin-bottom: 65px;
	//          figure img{
	//
	//                    width: 100%!important;
	//          }
}

.medium-insert-active {}

.medium-editor-insert-plugin {
	outline: 0 solid transparent;

	&:focus {
		outline: 0 solid transparent;
	}

	&:after,
	&:before,
	.clearfix:after,
	.clearfix:before {
		content: " ";
		display: table;
		clear: both;
	}

	p {
		/*margin: 1em 0;*/
	}

	progress {
		display: block;
		margin: 1em auto;
	}

	.hide {
		display: none;
	}

	&.medium-editor-placeholder:after {
		margin-top: 10px;
		@media (min-width: 768px) {
			//                              margin-left: 245px;
		}
		@media (min-width: 992px) {
			margin-left: 123px;
		}
		@media (min-width: 1200px) {
			margin-left: 225px;
		}
	}

	.medium-insert-buttons {
		position: absolute;
		color: #ddd;
		font-size: 0.9em;

		button {
			display: block;
			cursor: pointer;
			color: #242424;
			background: #f6f6f6;
			width: 32px;
			height: 32px;
			box-sizing: border-box;
			border-radius: 20px;
			border: 1px solid #ddd;
			line-height: 30px;
			text-align: center;
			padding: 0;
		}

		.medium-insert-buttons-show {
			font-size: 25px;
			transform: rotate(0);
			transition: transform 100ms;

			span {
				display: block;
				margin-top: -4px;
			}

			&.medium-insert-buttons-rotate {
				transition: transform 250ms;
				transform: rotate(45deg);
			}
		}

		.medium-insert-buttons-addons {
			margin: 0;
			padding: 0;
			list-style: none;
			display: none;
			position: relative;
			z-index: 2;
			left: 55px;
			top: -32px;

			li {
				display: inline-block;
				margin: 0 5px;

				.fa {
					font-size: 15px;
				}
			}
		}
	}
}

.medium-insert-caption-placeholder {
	position: relative;

	&:after {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		text-align: center;
		content: attr(data-placeholder);
	}
}

.medium-insert-embeds-input {
	text-align: center!important;
}
// CANCELLO  DIDASCALIA VIDEO
// .medium-insert-embeds figcaption { display: none; visibility: hidden;}

.descrizione-album > .medium-editor-placeholder {
	margin: 0 !important;
	&:after{
		// margin-left: 0px!important;
		padding-left: 0px!important;
	}
}
