//Variables

//Typography
$fontCalibri: Calibri, 'CalibriFF', Candara, Segoe, "Segoe UI" , Optima, Arial, sans-serif;
$fontCalvert: 'Calvert', serif;


$font-family-base: $fontCalibri;

$font-size-base:			14px;
$font-size-base-mobile:		15px;
$line-height-base:			1.2;

$font-size-h1: 				54px;
$font-size-h2: 				47px;
$font-size-h3: 				28px;
$font-size-h4: 				22px;
$font-size-h5: 				18px;
$font-size-h6: 				16px;

$font-size-h1-tablet: 48px;

//Grid

$grid-gutter-width: 30px;

// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-lg:                  1210px !default;

//== Container sizes
//
//## Define the maximum width of `.container` for different screen sizes.

// Medium screen / desktop
$container-desktop:            (960px + $grid-gutter-width) !default;


// Large screen / wide desktop
$container-large-desktop:      (1160px + $grid-gutter-width) !default;

//Colors

$text-color: #6b6c6c;

$brand-primary: #0c629f;
$brand-success: #92c201;
$brand-info: #0061a1;
$brand-warning: #fed02f;
$brand-danger: #c30200;
$brand-lightgreen: #20c1ae;

//Media queries
$mobile-sm: "(max-width: 374px)";
$mobile: "(max-width: 767px)";
$sm-screen: "(max-width: 991px)";
$md-screen: "(max-width: 1209px)";

//Usage:
//@media #{$md-screen} {
// ...
//}


//BOTTONI:
$btn-salva: #ffe019;
$btn-cancella: #f00;
$hover-salva: #d9bc00;
$hover-cancella: #c90000;


//COLORI LABELS

$color-reportage: #c30200;
$color-articolo: #002b4b;
$color-album: #fed02f;
$color-evento: #00c4ae;
$color-itinerario: #4e97cb;
$color-diario: #002b4b;
$color-gallery: #fed02f;

// DRUGO COLORS

$menucolor: #2c2c2c ;
$titlecolor: #3c3d41 ;
$txtcolor: #6b6c6c;
$txtboxcolor: #909191;

$txtchiarocolor: #cbcdcd;

$objgrigio: #aeaeae;
$objgrigiochiaro: #e8e8e8;

$backgroundgrigio: #f1f1f1;
